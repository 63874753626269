// import React from "react";
// import { serverSideTranslations } from "next-i18next/serverSideTranslations";

// import MainPageComponent from "../components/Homepage";
// import HeaderContainer from "../containers/Header";
// import { GetStaticProps } from "next";
// import FooterContainer from "../containers/Footer";
// import { SEOMapping } from "../config/seo";
// import { useRouter } from "next/router";
// import { useLinkTranslation } from "../custom-hooks/useLinkTranslation";
// import axios from "axios";

// const MainPage = ({ rating, reviews }) => {
// 	const { locale } = useRouter();
// 	const seo = SEOMapping["home"][locale];
// 	const { translateLink } = useLinkTranslation();

// 	const structuredData = {
// 		"@context": "https://schema.org",
// 		"@graph": [
// 			{
// 				"@type": "WebPage",
// 				"@id": "https://belgiumimmo.be",
// 				url: "https://belgiumimmo.be",
// 				inLanguage: locale,
// 				headline: seo?.title,
// 				datePublished: "2020-06-01T14:05:01+00:00",
// 				dateModified: "2022-06-01T12:24:14+00:00",
// 				isPartOf: {
// 					"@type": "Organization",
// 					"@id": "https://belgiumimmo.be",
// 					url: "https://belgiumimmo.be",
// 					name: "BelgiumImmo",
// 					description: seo?.description,
// 					foundingDate: "2019",
// 					sameAs: [
// 						"https://www.facebook.com/people/BelgiumImmo/100066385731159/",
// 						"https://www.linkedin.com/company/belgiumimmo/",
// 					],
// 					logo: {
// 						"@type": "ImageObject",
// 						"@id":
// 							"https://belgiumimmo.be/_next/static/images/belgium_immo_logo-1441c118503381365d19caa367986783.svg",
// 						url: "https://belgiumimmo.be/_next/static/images/belgium_immo_logo-1441c118503381365d19caa367986783.svg",
// 						width: "155px",
// 						height: "32px",
// 					},
// 				},
// 				publisher: {
// 					"@type": "Organization",
// 					"@id": "https://belgiumimmo.be",
// 					url: "https://belgiumimmo.be",
// 					name: "BelgiumImmo",
// 					description: seo?.description,
// 					foundingDate: "2019",
// 					sameAs: [
// 						"https://www.facebook.com/people/BelgiumImmo/100066385731159/",
// 						"https://www.linkedin.com/company/belgiumimmo/",
// 					],
// 					logo: {
// 						"@type": "ImageObject",
// 						"@id":
// 							"https://belgiumimmo.be/_next/static/images/belgium_immo_logo-1441c118503381365d19caa367986783.svg",
// 						url: "https://belgiumimmo.be/_next/static/images/belgium_immo_logo-1441c118503381365d19caa367986783.svg",
// 						width: "155px",
// 						height: "32px",
// 					},
// 				},
// 			},
// 		],
// 	};

// 	return (
// 		<>
// 			<HeaderContainer
// 				mainPage
// 				title={seo?.title}
// 				metaDescription={seo?.description}
// 				canonicalHref={translateLink(seo.canonical)}
// 				structuredData={structuredData}
// 			/>
// 			<MainPageComponent rating={rating} reviews={reviews} />
// 			<FooterContainer />
// 		</>
// 	);
// };

// export const getStaticProps: GetStaticProps = async ({ locale }) => {
// 	const namespaces = ["main-page", "header", "steps", "common", "agency-page"];
// 	const { data: rating } = await axios
// 		.create({ baseURL: process.env.REACT_APP_API })
// 		.get(`global-settings/GOOGLE_IB_RATING`);

// 	const { data: number_of_reviews } = await axios
// 		.create({ baseURL: process.env.REACT_APP_API })
// 		.get(`global-settings/GOOGLE_IB_REVIEWS`);

// 	const { data: reviews } = await axios
// 		.create({ baseURL: process.env.REACT_APP_API })
// 		.get(`agency-reviews/reviews/google`);

// 	return {
// 		revalidate: 60,
// 		props: {
// 			rating: {
// 				rating: rating?.value,
// 				number_of_reviews: number_of_reviews?.value,
// 			},
// 			reviews,
// 			...(await serverSideTranslations(locale, namespaces)),
// 		},
// 	};
// };

// export default MainPage;

import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React from "react";
import { useRouter } from "next/router";
import { faqs } from "../config/faqs";
import HeaderContainer from "../containers/Header";
import FooterContainer from "../containers/Footer";
import SeoHomePage from "../components/SeoHomePage";
import axios from "axios";
import { SEOMapping } from "../config/seo";
import { useLinkTranslation } from "../custom-hooks/useLinkTranslation";

const HomePage = ({ rating, properties }) => {
	const { locale } = useRouter();
	const _faqs = faqs["estimate-home"][locale];
	const seo = SEOMapping["home"][locale];
	const { translateLink } = useLinkTranslation();

	return (
		<div style={{ height: "100%" }}>
			<HeaderContainer
				title={seo?.title}
				metaDescription={seo?.description}
				canonicalHref={translateLink(seo?.canonical)}
			/>

			<div
				className="d-flex flex-column justify-content-between"
				style={{
					minHeight: "calc( 100vh - var(--headerHeight) )",
				}}
			>
				<SeoHomePage
					blockType={"buy"}
					faqs={_faqs}
					rating={rating}
					properties={properties}
				/>
				<FooterContainer />
			</div>
		</div>
	);
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	const namespaces = [
		"header",
		"common",
		"footer",
		"steps",
		"seo-home-page",
		"estimate-home",
		"search",
	];
	const { data: rating } = await axios
		.create({ baseURL: process.env.REACT_APP_API })
		.get(`global-settings/GOOGLE_IB_RATING`);

	const { data: number_of_reviews } = await axios
		.create({ baseURL: process.env.REACT_APP_API })
		.get(`global-settings/GOOGLE_IB_REVIEWS`);

	const { data: properties } = await axios
		.create({ baseURL: process.env.REACT_APP_API })
		.get("general-properties");
	// console.log(properties.length);

	const _serverSideTranslations = await serverSideTranslations(
		locale,
		namespaces
	);

	return {
		props: {
			rating: {
				rating: rating?.value,
				number_of_reviews: number_of_reviews?.value,
			},
			properties,
			..._serverSideTranslations,
		},
	};
};

export default HomePage;
